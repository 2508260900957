body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #C5D3E8;
    overflow-x: hidden;

    font-family: "Inter", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;

    touch-action: manipulation;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}


/* BOTTOM PAGE */
.footer-section {
    background-color: #e2effb;
}

.footer-container {
    background-color: #F2F9FF;
    text-align: center;
    border-top-right-radius: 200px;
}

.developer-details {
    padding: 20px;
    margin-bottom: 20px;
}

.input-group {
    margin-bottom: 15px;
    text-align: left;
}

.input-label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.input-field,
.textarea-field {
    width: 95%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submit-button {
    background-color: #007bff;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    width: 100%;
    font-size: 17px;
    font-weight: 500;
}

.submit-button:hover {
    background-color: #0056b3;
}


.footer-bottom-section {
    background-color: #C5D3E8;
    margin-top: 20px;
    padding: 10px;
    font-weight: 600;
}

.footer-text {
    font-size: 0.9rem;
    color: #666;

    padding: 20px;
}

.social-links {
    display: grid;
    grid-template-columns: 50% 50%;
    /* Two columns */
    justify-items: center;
    /* Center items horizontally */
    margin: 20px;
    margin-top: 0 !important;
}

.social-link {
    display: flex;
    cursor: pointer;
    font-weight: bold;
    padding: 12px;
    width: 90%;
    align-items: center;
    text-align: center;
    border-bottom: 4px solid;
}


.social-logo {
    width: 18px;
    height: 18px;
    object-fit: contain;
    margin-right: 10px;
}

.picure_header {
    position: relative;
    margin-right: 10px;
    align-items: center;
    border-radius: 600px;
    overflow: hidden;
    width: 35px;
    height: 35px;
    background-color: #8cc0ee;
    /* Initial background color */
    border: 3px solid #8cc0ee;
    /* Initial border color */
    cursor: pointer;
    animation: colorChange 3s infinite;
    /* Apply the animation */
}

@keyframes colorChange {
    0% {
        border-color: #8cc0ee;
    }

    50% {
        border-color: #b22ff9;
    }

    100% {
        border-color: #8cc0ee;
    }
}

.picure_header img {
    width: 100%;
    height: auto;
}

.dropdown_menu {
    position: absolute;
    z-index: 100;
    top: 100px;
    padding: 10px;
    background-color: #ebf5fe;
    border: 1px solid #e9f5ff;
    border-top: 1.5px solid #daecfa;
    border-radius: 8px 2px 4px 4px;
    width: 240px;
    right: 30px;
}

.dropdown_menu div {
    width: 90%;
    margin: auto;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    border: 0;
    border-bottom: 1px solid #e1f0fd;
}

.dropdown_menu div:last-child {
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    border: 0 !important;
}

.dropdown_menu div:hover {
    color: #a4aaac;
}