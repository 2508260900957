.intro {
    padding: 24px;
    background-color: rgb(234, 242, 248);
    position: relative;
    min-height: 600px;
    align-items: center;
    color: rgb(88, 93, 98);
    overflow: hidden;
}


.web_intro {
    position: relative;
    max-width: 1200px;
    margin: auto;
    min-height: 600px;
}

.wild_intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 100px;
}

.california_intro {
    max-width: 400px;
    text-align: center;
    z-index: 100;
    margin: auto;
}

.ca_title p {
    font-size: 15px;
    font-weight: 500;
}

.ca_title h2 {
    font-size: 26px;
    margin-bottom: 50px;
}

.ca_bio p {
    font-size: 17px;
    text-align: center;
    max-width: 400px;
    margin: auto;
    margin-top: 23px;
    line-height: 1.4;
    /* Improves readability */
    font-weight: 500;
}

.chicago_intro {
    width: 40%;
    max-width: 400px;
    margin: auto;
}

.chicago_images {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.chicago_images img {
    width: auto;
    height: 200px;
    transition: opacity 0.5s;
}

.fade-left {
    mask-image: linear-gradient(to right, transparent, black);
    -webkit-mask-image: linear-gradient(to right, transparent, black);
}

.fade-right {
    mask-image: linear-gradient(to left, transparent, black);
    -webkit-mask-image: linear-gradient(to left, transparent, black);
}

.modern-intro {
    position: relative;
}

.continer_titile {
    display: flex;
    /* Use flexbox */
    flex-direction: column;
    /* Stack items vertically */
    align-items: center;
    /* Center horizontally */
    justify-content: center;
    /* Center vertically */
    text-align: center;
    /* Ensure text is centered */
}

.container h1 {
    font-size: 26px;
    text-align: center;
    /* Redundant but ensures alignment */
    margin-bottom: 10px;
    /* Add space between h1 and the icon */
}

.container h1 mark,
.courses_head h2 mark,
.liberay_title mark,
.ca_title h2 mark {
    font-size: 24px;
    font-family: "Pacifico", serif;
    font-weight: 600;
    font-style: normal;
    background: none;
    color: #369aff;
}

.container p {
    font-size: 17px;
    text-align: center;
    max-width: 400px;
    margin: auto;
    margin-top: 23px;
    line-height: 1.4;
    /* Improves readability */
    font-weight: 420;
}

.continer_titile div {
    font-size: 16px;
    font-weight: 700;
}

.icon-container {
    display: inline-block;
    animation: fade-in 1s ease-in-out;
    /* Smooth fade-in animation */
}

.filtered-icon {
    width: 20px;
    filter: invert(54%) sepia(80%) saturate(400%) hue-rotate(189deg) brightness(95%) contrast(95%);
    /* Color filter to match #369aff */
}

/* Fade-in animation */
@keyframes fade-in {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}



.anime {
    position: absolute;
    top: 60px;
    right: 60px;
    margin-bottom: 100px;
}

.anime_tap {
    width: 300px;
    background-color: #c1e8ff;
    padding: 20px;
    border: 0;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    border: 0;
    border-right: 10px solid #d6ecf9;
    height: 230px;
}

.anime_butt {
    width: 300px;
    margin: auto;
    padding: 16px;
    background-color: #38b6ff;
    border-right: 10px solid #acdefb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    border-radius: 2px;
    cursor: pointer;
}

.anime_butt:hover {
    background-color: #5fc4ff;
    border-right: 10px solid #bbe1f7;
}


.anime_butt_signup {
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #fbfcfe;
    justify-content: space-between;
}

.anime_butt_svg {
    background-color: #d6ebf8;
    width: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    height: 40px;
    border-radius: 100px;
}

.anime_header {
    position: relative;
    right: -90px;
    padding: 16px;
    width: 240px;
    background-color: #369aff;
    color: aliceblue;
    border-radius: 8px 8px 0px 8px;
    font-size: 15px;
}

.anime_title {
    font-family: "Pacifico", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    text-align: center;
    color: #369aff;
    margin-bottom: 20px;
}

.anime_continer {
    position: relative;
    margin-top: 20px;
    right: 50px;
    padding: 16px;
    width: 260px;
    background-color: #a1b7be;
    color: aliceblue;
    border-radius: 8px 8px 8px 0px;
    font-size: 15px;
}

.info_message_anime {
    display: flex;
    justify-content: left;
    align-items: center
}

.info_message_anime img {
    position: relative;
    top: 2px;
    filter: invert(100%) brightness(100%) contrast(100%) saturate(0%);
}

.time_nn {
    font-size: 12px;
    margin-left: 5px;
}

.modern-intro {
    max-width: 400px;
    z-index: 100;
    position: relative;
    top: 40px;
}


.library {
    background-color: #d6ecf9;
    margin: auto;
    padding-bottom: 20px;
    padding-top: 20px;
}

.liberay_title {
    padding: 20px;
    font-size: 24px;
    font-weight: 700;
    color: rgb(88, 93, 98);
    text-align: center;
}

.library-container {
    margin: 12px;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 50% 50%;
    /* Adds space between items */
    justify-content: left;
}

.library-item {
    background-color: #bbe1f7;
    color: #788389;
    padding: 10px;
    display: flex;
    border-radius: 18px;
    justify-content: center;
    align-items: center;
    margin: 1px;
    /* Centers the grid items */

    opacity: 0.2;
    /* Makes it 10% visible */
    transition: opacity 0.6s;
    /* Smooth transition effect */
    border: 1px solid #bbe1f7;
}


.slc {
    background-color: #acdbf6;
    color: #2d2e2f;
    opacity: 1;
    /* Makes it 10% visible */
    /* Smooth transition effect */
    border: 1px solid #85c5e9;
}

.slc_ {
    background-color: #bbe1f7;
    color: #2d2e2f;
    opacity: 0.4;
    /* Makes it 10% visible */
    /* Smooth transition effect */
    border-top: 1px solid #379bff;
}

._slc {
    background-color: #bbe1f7;
    color: #2d2e2f;
    opacity: 0.4;
    /* Makes it 10% visible */
    /* Smooth transition effect */
    border-bottom: 1px solid #379bff;
}

.library-content h3 {
    font-size: 16px;
}

.library-content p {
    font-size: 15px;
}

.library-icon {
    position: relative;
    min-height: 60px !important;
    min-width: 60px !important;
    border-radius: 50%;
    /* Ensures a perfect circle */
    background-color: #cde9f9;
    display: flex;
    /* Centers content inside the circle */
    align-items: center;
    justify-content: center;
    overflow: hidden;
    /* Ensures no content overflows the circle */
}

.library-item img {
    opacity: 0.7;
    /* Optional: Slightly reduces brightness for a subtle effect */
    filter: invert(63%) sepia(23%) saturate(1055%) hue-rotate(168deg) brightness(103%) contrast(93%) !important;
}

.library-content {
    width: 100%;
    /* Ensures content fits properly */
    text-align: left;
    /* Centers text */
    margin-left: 20px;
}

.courses {
    padding: 20px;
    background-color: #d6ecf9;
}

.courses_head {
    margin: 20px;
    padding: 10px;
    color: rgb(88, 93, 98);
}

.courses_head h2 {
    font-size: 24px;
    font-weight: 700;
    padding: 10px;
    border-radius: 100px;
    text-align: center;
}

.course-item img {
    position: relative;
}

.course-list {
    overflow: auto;
    scroll-behavior: smooth;
}

.course-item {
    min-width: 300px;
    margin-right: 10px;
    font-size: 16px;
}

.hussein {
    margin-bottom: 10px;
    border-radius: 40px;
    overflow: hidden;
}

.alaa {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    background-color: #5fc4ff;
    background-image: linear-gradient(to right, #8fd0f7, #4bb9f9);
    font-size: 14px;
    cursor: pointer;
}

.alaa img {
    margin-right: 10px;
}

.item-shape {
    overflow: hidden;
    font-size: 16px;
    padding: 10px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    justify-content: center;
    color: white;
    position: relative;
    cursor: pointer;
}
.shape_flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.item-shape:hover {
    filter: brightness(0.8); /* Darken the background */
}

.item-logo {
    display: flex;
    position: relative;
    margin: auto;
    text-align: center;
    align-items: center;
    background-color: #369aff;
    border: 3px solid #369aff;
    border-radius: 50px;
    min-width: 80px;
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;
    overflow: hidden;
    margin-right: 20px;
    margin-left: 10px;
    margin-top: 6px;
}

.item-logo img {
    height: 100%;
    width: 80px;
    margin-right: 13px;
}

.item_logo_title {
    position: relative;
    margin: auto;
    align-items: center;
    width: 100%;

}

.item_logo_title h3 {
    padding: 0;
    margin: 0;
    font-size: 18px;
}

.item_logo_title h3 mark {
    background: none;
    color: #d8ecff;
    font-weight: 600;
}

.item_logo_title h4 {
    padding: 8px 0 0 0px;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
}

.item_logo_title h4 mark {
    background: none;
    font-weight: 600;
    text-decoration: underline #6593ef;
    cursor: pointer;
}

.item_logo_title p {
    padding: 7px 0 0 0;
    margin: 0;
    font-size: 15px;
}

.item_logo_title mark {
    font-weight: 500;
    background: none;
    color: #fbfcfe;
}

.tags {
    display: flex;
    margin-left: 20px;
}

.tag_ {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    margin-right: 10px;
    background-color: #23d6d6;
    width: fit-content;
    margin-top: 6px;
}
.courses_message {
    display: flex;
    width: 80%;
    max-width: 800px;
    margin: auto;
    padding: 14px;
    background-color: #cde9f9;
    font-size: 17px;
    border-radius: 10px;
    border: 1px solid #c2e0f1;
    margin-bottom: 30px;
}
.courses_message img{
    filter: invert(63%) sepia(23%) saturate(1055%) hue-rotate(168deg) brightness(103%) contrast(93%) !important;

    margin-right: 20px;
}

.tag_new {
    background-color: #369aff;
}

.tag_python {
    background-color: #b23d2e !important;
}

.tag_long {
    background-color: #23d6d6;
}
