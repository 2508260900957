.container {
    overflow: hidden;
}

.home_header_pck {
    background-color: #dbe7f3;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 10000;
    border: 0;
}

.home_header {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 700px;
    margin: auto;
}

.home_header_pack_one {
    display: flex;
    justify-content: center;
    border-radius: 20px;
    overflow: hidden;
    width: 80%;
    text-align: center;
}

.home_header_pack_two {
    width: 20%;
    max-width: 80px;
    display: flex;
    text-align: center;
    justify-content: center;
    border-radius: 20px;
    overflow: hidden;
    margin-left: 5px;
}

.home_header_pack_two div {
    display: flex;
    height: 20px;
    padding: 16px 20px 16px 20px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    background-color: #f0f6fa;
}

.home_header_pack_one div {
    display: flex;
    height: 20px;
    padding: 16px 20px 16px 20px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    background-color: #f0f6fa;
    border-right: 2px solid #e8eff5;
}

.home_header_pack_one div img,
.home_header_pack_two div img {
    display: flex;
    /* Enables flexbox */
    align-items: center;
    /* Centers vertically */
    justify-content: center;
    text-align: center;
    margin: auto;
    width: 16px;
    filter: invert(30%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(60%) contrast(100%);
}

.homeicon img {
    width: 19px !important;
}

.active {
    background-color: #d4ebff !important;
    border-right: 0 !important;
}

.active img {
    filter: invert(63%) sepia(23%) saturate(1055%) hue-rotate(168deg) brightness(103%) contrast(93%) !important;
}

.active_ntop img {
    filter: invert(63%) sepia(23%) saturate(1055%) hue-rotate(168deg) brightness(103%) contrast(93%) !important;
}

.active_ntop {
    border: 3px solid #c8d7f8;
    /* Initial border color */
}


.error_container {
    padding: 20px;
    margin: auto;
    width: 90%;
    max-width: 400px;
    border-radius: 4px;
    background-color: #e0c9aa;
    display: flex;
    align-items: center;
}

.error_container img {
    margin-right: 30px;
    margin-left: 10px;
}

.profile_main {
    background-color: #f2f8fc;
}

.chicago_bar {
    display: flex;
    justify-content: space-around;
    margin: 15px;
    border-top: 1px solid #d8e0ee;
}

.chicago_bar div {
    padding: 14px 8px 8px 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
}

.chicago_bar_selected {
    border-bottom: 3px solid #2996f5;
}

.profile_main_con {
    min-height: 500px;
    background-color: #d8e0ee;
}

.conver_pic {
    width: 100%;
    height: 120px;
    background-color: #2996f556;
    overflow: hidden;
}

.conver_pic img {
    width: 100%;
    height: auto;
    align-items: center;
}

.profile_pic {
    position: relative;
    padding: 20px;
    top: -80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pic_pic {
    width: 120px;
    height: 120px;
    background-color: #eff5ff;
    border: 2px solid #c2daee;
    border-radius: 500px;
    overflow: hidden;
}

.pic_pic img {
    width: 100%;
}

.pic_edit {
    display: flex;
    align-items: center;
    background-color: #ddebf8;
    border: 1px solid #c2daee;
    padding: 10px;
    font-size: 16px;
    border-radius: 60px;
    width: 120px;
    cursor: pointer;
}

.pic_edit:hover {
    background-color: #c2daee;
}

.pic_edit img {
    margin-right: 10px;
    margin-left: 4px;
}

.username_and_discription {
    margin: 0px 30px 0px 30px;
    position: relative;
    top: -80px;
}

.name_co_ {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 22px;
}

.verified {
    margin-left: 5px;
    width: 24px;
    filter: invert(63%) sepia(23%) saturate(1055%) hue-rotate(168deg) brightness(103%) contrast(93%) !important;
}

.username_con_ {
    color: #4f5558;
    font-size: 16px;
}

.description_con_ {
    margin-top: 20px;
    font-size: 17px;
}

.chicago_mine {
    top: -20px;
    position: relative;
    margin: 0px 30px 0px 30px;
}

.info_chicago {
    display: flex;
    justify-content: space-between;
}

.info_chicago_ {
    margin: auto;
    margin-bottom: 40px;
    margin-top: 20px;
}

.info_chicago div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

.info_chicago div img {
    margin-right: 8px;
}

.edit_profile {
    background-color: #F2F9FF;
}

.edit_profile_header {
    padding: 10px;
    width: 90%;
    margin: auto;
}

.edit_profile_header {
    display: flex;
    justify-content: space-between;
}

.exit_editor {
    padding: 8px;
    width: 60px;
    text-align: center;
    background-color: #f65c54;
    color: #eff5ff;
    font-size: 16px;
    font-weight: 800;
    border-radius: 30px;
    border: 1px solid #ec453d;
    cursor: pointer;
}

.save_editor {
    padding: 8px;
    width: 60px;
    text-align: center;
    background-color: #5467f6;
    color: #eff5ff;
    font-size: 16px;
    font-weight: 800;
    border-radius: 30px;
    border: 1px solid #4155eb;
    cursor: pointer;
}

.cover_edit {
    width: 100%;
    height: 200px;
    overflow: hidden;
    position: relative;
}

._cover_edit {
    height: 100%;
}

._cover_edit img {
    width: 100%;

}

._cover_edit_ {
    position: absolute;
    width: 100%;
    height: 100%;
}

._cover_edit_ img {
    position: absolute;
    text-align: center;
    margin: auto;
    padding: 10px;
    width: 30px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #c2daeebe;
    border-radius: 10px;
    cursor: pointer;
}

.profile_pic_edit {
    overflow: hidden;
    position: relative;
    top: -50px;
    border: 4px solid #c2daee;
    width: 125px;
    height: 125px;
    margin-left: 20px;
    border-radius: 100px;
}

._pic_edit_ {
    position: absolute;
    width: 100%;
    height: 100%;
}

._pic_edit_ label {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
}

._pic_edit_ label img {
    text-align: center;
    margin: auto;
    align-items: center;
    padding: 10px;
    width: 40px;
    height: 40px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #c2daeebe;
    border-radius: 10px;
    cursor: pointer;
}

.input_edit_group {
    width: 88%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.input_edit_group div {
    margin-right: 10px;
    width: 45%;
}

.input_edit_ {
    width: 100%;
    position: relative;
    padding-bottom: 40px;
}

.input_edit_ label {
    position: absolute;
    top: -15px;
    font-size: 17px;
    z-index: 10;
    left: 10px;
    background-color: #F2F9FF;
}

.input_edit_ input {
    width: 95%;
    padding: 10px;
    margin: auto;
    border: 1px solid #c2daee;
    border-radius: 4px;
    font-size: 16px;
}

.input_edit_ind {
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
}

.edit_faild {
    padding: 10px;
    font-size: 17px;
    text-align: center;
    width: 79%;
    margin: auto;
    background-color: #f9c4c2;
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 4px;
}

.cr {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.cr::after {
    content: "";
    border: 4px solid #e8eff5;
    border-top: 4px solid #2996f5;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.edit_succ {
    padding: 20px;
    margin: 10px;
    border-radius: 4px;
    background-color: #85d0ba;
    font-size: 17px;
}

._pic_edit {
    background-color: #9accec;
    width: 100%;
}

._pic_edit img {
    width: 100%;
    height: 100%;
}

.search_container {
    background-color: #e7f0f8;
    position: relative;
    padding: 20px;
}

.search_box {
    position: relative;
    display: flex;
    /* Takes 90% of the page width */
    align-items: center;
    margin: auto;
}

.search_box input {
    /* Takes the remaining space (80%) */
    padding: 20px;
    border: none;
    outline: none;
    width: 100%;
    font-size: 14px;
    border-radius: 10px;
    background-color: #d3eaf7;
    padding-left: 23px;
    /* Ensures text inside input is also aligned */
    border: 1px solid #d4ebff;
    margin-right: 10px;
    color: rgb(48, 50, 51);
    font-size: 15px;
}

.search_box input::placeholder {
    font-size: 15px;
    /* Optional: Makes placeholder text lighter */
}


.search_button {
    cursor: pointer;
    position: absolute;
    right: 30px;
    flex-shrink: 0;
    /* Prevents shrinking */
    width: 20%;
    /* Takes 20% of the width */
    width: 45px;
    height: 45px;
    /* Max width to prevent it from being too large */
    background-color: #eaf3f8;
    color: white;
    border: none;
    border-radius: 500px;
}

.search_button img {
    width: 17px;
    filter: invert(63%) sepia(23%) saturate(1055%) hue-rotate(168deg) brightness(103%) contrast(93%) !important;
}

.search_button:hover {
    background-color: #c6e1f9;
}

.search_suggestions {
    margin: auto;
}

.results {
    background-color: #eaf3f8;
    padding: 20px;
}

.sort {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-size: 14px;
    border-top: 1px solid #c2daee;
}

.user_item {
    width: auto;
    margin: auto;
    display: flex;
    align-items: normal;
    padding: 12px;
    background-color: #48a8fc;
    margin-bottom: 20px;
    border-radius: 34px;
    color: white;
    min-height: 80px;
}

.user_info_name {
    font-size: 15px;
    font-weight: 600;
}

.user_info_username {
    font-size: 14px;
    margin-top: 3px;
    text-decoration: underline solid;
    cursor: pointer;
}

.user_info_bio {
    font-size: 15px;
    width: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.user_image {
    position: relative;
    border-radius: 500px;
    min-width: 70px;
    max-width: 70px;
    min-height: 70px;
    max-height: 70px;
    margin-right: 10px;
    border: 3px solid #71b7f4;
    overflow: hidden;
}

.user_image img {
    position: absolute;
    width: 100%;
}

.sign_offer {
    position: fixed;
    width: 90%;
    max-width: 600px;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);
    align-items: center;
    border: 1px solid rgb(231, 244, 254);
    background: rgba(231, 244, 254, 0.729);
    /* Slightly transparent white-blue background */
    backdrop-filter: blur(10px);
    /* Blurry effect */
    border-radius: 10px;
    z-index: 100;
    text-align: center;
    color: #4f5558;
}

.sign_offer p {
    width: 70%;
    margin: auto;
    padding: 16px;
    font-size: 17px;
    font-weight: 700;
    border: 0;
    border-bottom: 1px solid #c7d4dbb6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign_offer p img {
    filter: invert(30%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(60%) contrast(100%);
    margin-right: 10px;
}

.sign_offer p mark {
    font-family: "Pacifico", serif;
    font-style: normal;
    background: none;
    color: #369aff;
    margin: 0 6px;
}

.sign_bott {
    font-size: 14px;
    padding: 10px;
}

.offer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 10px;
    padding-top: 15px;
    margin-right: 0;
    font-weight: 700;
}

.so_login {
    padding: 10px;
    width: 60px;
    text-align: center;
    background-color: #04b3f2;
    color: aliceblue;
    border-radius: 3px;
    cursor: pointer;
    margin-right: 6px;
}

.so_signup {
    padding: 10px;
    width: 80px;
    text-align: center;
    background-color: #28c872;
    color: white;
    border-radius: 3px;
    cursor: pointer;
    margin-left: 6px;
}

.so_signup:hover {
    background-color: #0d954d;
}

.so_login:hover {
    background-color: #2298ff;
}