.main_header {
  background-color: #F2F9FF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px 20px 30px;
  color: hsl(210, 6%, 35%);
}

/* Logo image */
.logo-main {
  cursor: pointer;
}

/* Full-page container */
.loading_main {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  /* Optional background */
}

/* Logo container */
.loading_logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

._logo {
  width: 60px;
  object-fit: contain;
}


.logo {
  height: 70px;
  pointer-events: none;
}

.profile_icon {
  width: 100%;
}

.profile_logo {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  background-color: blue;
  border: 2px solid #194fa5;
  margin-right: 10px;
  /* commented for demo
  float: left;
  margin-top: 20px;
  */

  /*for demo*/
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  /* Add transition for smooth effect */
  transition: filter 0.3s ease, border-color 0.3s ease;
}

.profile_logo:hover {
  border: 2px solid #04b3f2;
  filter: brightness(0.7);
  /* Darkens the image */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.modern-footer {
  background-color: #F2F9FF;
  padding: 20px;

}


.header-ask {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.header-login {
  padding: 10px;
  color: #6f7577;
  border-radius: 3px;
}

.header-login:hover {
  padding: 10px;
  color: black;
}

.header-signup {
  padding: 10px;
  background-color: #04b3f2;
  color: aliceblue;
  margin-left: 10px;
  border-radius: 3px;
}

.header-signup:hover {
  background-color: #038eff;
}


/* LOGIN SIGNUP */

.page_login_signup {
  padding: 20px;
  max-width: 700px;
  margin: auto;
}

.page_login_signup h2 {
  font-size: 22px;
  font-weight: 800;
  color: #474a50;
  margin-bottom: 30px;
  text-align: center;
}

.page_login_signup form {
  margin-top: 10px;
}

.enter_message {
  padding: 20px;
  font-size: 17px;
  background-color: #ade3f3ee;
  border-radius: 4px;
}

.form-group {
  padding: 7px;
  background-color: #F2F9FF;
  position: relative;
  margin-top: 20px;
  border-radius: 2px;
  display: flex;
}

.form-group label {
  position: absolute;
  top: -14px;
  left: 15px;
  font-size: 17px;
  font-weight: bold;
  color: #474a50;
}

.form-group input {
  padding: 10px;
  border: 0;
  background: none;
  width: 95%;
  font-size: 16px;
  outline: 0;
  border: 0;
}

.form-group select {
  padding: 10px;
  width: 100%;
  font-size: 16px;
  border: 0;
  background: none;
  cursor: pointer;
}

.btn {
  padding: 10px;
  margin-top: 20px;
  width: 100%;
  font-size: 17px;
  border: 0;
  background-color: #108ae7;
  color: #F2F9FF;
  font-weight: bold;
  border-bottom: 3px solid #1079c9;
  border-right: 3px solid #1079c9;
  cursor: pointer;
}

.toggleAny {
  padding: 10px;
  font-size: 17px;
  font-weight: bold;
  color: #474a50;
  margin-top: 10px;
}

.toggle-btn- {
  padding: 10px;
  font-size: 15px;
  color: #474a50;
  font-weight: bold;
  border: 0;
  width: 100%;
  cursor: pointer;
}

.toggle-btn {
  padding: 10px;
  font-size: 15px;
  color: #474a50;
  font-weight: bold;
  border: 0;
  width: 100%;
  cursor: pointer;
  background-color: #0b9c9a;
  color: #F2F9FF;
  border-bottom: 3px solid #087674;
  border-right: 3px solid #087674;
}

.toggle-btn:hover {
  background-color: #087674;
  color: #F2F9FF;
}

.form-group button {
  border: 0;
  outline: 0;
  color: #474a508d;
  font-size: 13px;
  cursor: pointer;
  background: none;
  font-weight: bold;
  margin-right: 10px;
}

.panel {
  background-color: #F2F9FF;
  border-top: 1px solid #edf4f9;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}

.basic_panel {
  display: flex;
}

.right_panel {
  display: flex;
  justify-content: space-between;
}

.right_panel a {
  text-decoration: none;
  color: #0e2f64;
}

.explore_courses {
  margin-right: 20px;
  width: 130px;
  border-radius: 200px;
  background-color: #e6f1fb;
  border: 1px solid #a6ccf0;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.basic_panel div {
  text-align: center;
  align-items: center;
  margin: auto;
  cursor: pointer;
  border: 0;
  border-right: 1px solid #d0d4d9;
  padding-right: 20px;
  padding-left: 20px;
}

.basic_panel div:last-child {
  border: 0;
}

.panel_more {
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 100px;
  padding: 10px;
  border-radius: 200px;
  border: 1px solid #e6edf4;
  text-align: center;
}

.panel_more img {
  margin-left: 10px;
}

.header_selected {
  color: #038eff !important;
  font-weight: bold;
}

.main_header_selected {
  border: 0 !important;
  text-align: center;
  border-radius: 40px;
  padding: 10px;
  background-color: #edf4f9;
}

.main_header_selected img {
  filter: invert(63%) sepia(23%) saturate(1055%) hue-rotate(168deg) brightness(103%) contrast(93%) !important;
}

.dropdown_content {
  z-index: 1000;
  width: 100%;
  top: 155px;
  background-color: #F2F9FF;


  display: flex;
  flex-direction: column;
}

.dropdown_section {
  padding: 10px;
  margin: 10px;
}

.dropdown_section:first-child {
  background-color: #edf4f9;
  border-bottom: 2px solid #f4f6f7;
}

.dropdown_section:nth-child(2) {
  background-color: #edf4f9;
  border-bottom: 2px solid #f4f6f7;
}

.dropdown_section:nth-child(3) {
  color: #228ab3;
  margin-bottom: 30px !important;
  padding-top: 30px !important;
  border-top: 2px solid #edf4f9;
  display: flex;
  justify-content: space-around;
  font-weight: bold;
}

.dropdown_section div {
  cursor: pointer;
  padding: 5px;
  border-radius: 3px;
}

.dropdown_section div:hover {
  background-color: #F2F9FF;
  text-decoration: none;
}

.loading-message {
  padding: 10px;
  background-color: #04b3f2;
  border-radius: 2px;
  margin: 10px;
}


.cookies-banner {
  position: fixed;
  text-align: center;
  bottom: 0px;
  width: 100%;
  background: rgba(5, 72, 130, 0.8);
  color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.cookies-banner h2 {
  font-size: 18px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}


.cookies-banner h2 img {
  margin-right: 10px;
  filter: brightness(0) invert(1);
}

.cookies-banner p {
  width: 90%;
  margin: auto;
  text-align: left;
  font-size: 16px;
  padding: 16px;
}

.accept_cookie {
  width: 90%;
  padding: 16px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 2px;
  background-color: #d9dee4;
  border: 2px solid #b1b6bd;
  cursor: pointer;
  margin-bottom: 20px;
  color: #313236;
}

.cct {
  filter: brightness(0) invert(1);
  width: 30px;
  padding: 6px;
  top: 20px;
  right: 20px;
  position: absolute;
  cursor: pointer;
  background-color: #d9dee428;
  border: 2px solid #b1b6bd9c;
  border-style: dashed;
  border-radius: 2px;
}

.cct img {
  width: 10px;
}

.accept_cookie:hover {
  background-color: #cbccce;
}

.user_not_found {
  width: 80%;
  max-width: 600px;
  margin: auto;
  margin-top: 100px;
  padding: 20px;
  background-color: #e8f1f74d;
  text-align: center;
  height: 100px;
  font-size: 17px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

.scene {
  position: relative;
  z-index: 2;
  height: 120px;
  width: 120px;
  display: grid;
  place-items: center;
}

.cube-wrapper {
  transform-style: preserve-3d;
  animation: bouncing 2s infinite;
}

.cube {
  transform-style: preserve-3d;
  transform: rotateX(45deg) rotateZ(45deg);
  animation: rotation 2s infinite;
}

.cube-faces {
  transform-style: preserve-3d;
  height:20px;
  width: 20px;
  position: relative;
  transform-origin: 0 0;
  transform: translateX(0) translateY(0) translateZ(-40px);
}

.cube-face {
  position: absolute;
  inset: 0;
  background: #1c95f9;
  border: solid 1px #f8f9fa;
}

.cube-face.shadow {
  transform: translateZ(-20px);
  animation: bouncing-shadow 2s infinite;
}

.cube-face.top {
  transform: translateZ(20px);
}

.cube-face.front {
  transform-origin: 0 50%;
  transform: rotateY(-90deg);
}

.cube-face.back {
  transform-origin: 0 50%;
  transform: rotateY(-90deg) translateZ(-20px);
}

.cube-face.right {
  transform-origin: 50% 0;
  transform: rotateX(-90deg) translateY(-20px);
}

.cube-face.left {
  transform-origin: 50% 0;
  transform: rotateX(-90deg) translateY(-20px) translateZ(20px);
}

@keyframes rotation {
  0% {
    transform: rotateX(15deg) rotateY(0) rotateZ(45deg);
    animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
  }

  50% {
    transform: rotateX(15deg) rotateY(0) rotateZ(225deg);
    animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
  }

  100% {
    transform: rotateX(15deg) rotateY(0) rotateZ(405deg);
    animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
  }
}

@keyframes bouncing {
  0% {
    transform: translateY(-10px);
    animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
  }

  45% {
    transform: translateY(10px);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }

  100% {
    transform: translateY(-10px);
    animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
  }
}

@keyframes bouncing-shadow {
  0% {
    transform: translateZ(-80px) scale(1.3);
    animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    opacity: 0.05;
  }

  45% {
    transform: translateZ(0);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    opacity: 0.3;
  }

  100% {
    transform: translateZ(-80px) scale(1.3);
    animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    opacity: 0.05;
  }
}

/*  Mobile ADJUSTER FOR ALL CSS's files */

@media only screen and (min-width: 600px) {
  .inputs_group {
    display: flex;
    justify-content: space-between;
  }

  .inputs_group .form-group {
    width: 46% !important;
  }
}


@media only screen and (max-width: 750px) {
  .explore_web {
    display: none;
  }

  .main_header_selected {
    display: none;
  }

  .library-container {
    display: block !important;
  }

  .item-logo {
    min-width: 55px;
    max-width: 55px;
    min-height: 55px;
    max-height: 55px;
  }

  .item-logo img {
    height: 100%;
    width: 55px;
    margin-right: 13px;
  }
}

.pn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notifications_icon_top {
  display: none;
}

@media only screen and (max-width: 800px) {
  .notifications_icon_bottom {
    display: none !important;
  }

  .notifications_icon_top {
    display: flex !important;
    margin-right: 20px;
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 600px;
    background-color: #dbe3f6;
    border: 3px solid #dbe3f6;
    /* Initial border color */
  }

  .notifications_icon_top img {
    position: relative;
    width: 14px;
    filter: invert(30%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(60%) contrast(100%);
  }

  .wild_intro {
    display: block;
  }

  .anime {
    position: relative;
    right: auto;
  }

  .modern-intro {
    max-width: 100%;
  }

  .anime_tap {
    width: 240px;
    right: auto !important;
    left: auto !important;
    margin: auto !important;
  }

  .anime_header {
    padding: 14px;
    width: 240px;
    right: -40px;
  }

  .anime_continer {
    padding: 14px;
    width: 240px;
  }

  .intro {
    min-height: 600px
  }

  .modern-intro {
    top: 0px;
  }
}